import { Button, TableCell, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
    orderTypeStyle,
    ReturnOrderStatusList,
    returnOrderTypeList,
} from '../../constants/orderType'

const ReturnOrderList = ({ returnOrder }) => {
    const navigate = useNavigate()

    return (
        <>
            <TableRow>
                <TableCell>
                    <div
                        style={{
                            ...orderTypeStyle[returnOrder.returnOrderType],
                            textAlign: 'center',
                            padding: '5px',
                            borderRadius: '5px',
                            width: '80px',
                        }}
                    >
                        {returnOrderTypeList[returnOrder.returnOrderType]}
                    </div>
                </TableCell>
                <TableCell>{returnOrder?.returnInvoiceNumber}</TableCell>
                <TableCell>{returnOrder?.poNumber}</TableCell>
                <TableCell>{returnOrder?.clientName}</TableCell>
                <TableCell>{returnOrder?.company}</TableCell>
                <TableCell>
                    {returnOrder.returnOrderType !==
                        ReturnOrderStatusList.NEW &&
                        `$${returnOrder.returnOrderAmount}`}
                </TableCell>
                <TableCell>
                    <div className="button-box">
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                navigate(`/returnorder/${returnOrder.id}`)
                            }
                        >
                            Info
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default ReturnOrderList
