import React from 'react'
import logoList from '../../constants/logoList'

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth'

const ReturnInvoiceHeader = ({ returnOrder }) => (
    <>
        <div className="invoice-header-container">
            <div className="invoice-header-logo">
                <img
                    src={logoList?.[CLIENT]?.LOGO}
                    alt="logo"
                    className="invoice-header-logo-size"
                />
            </div>
            <div className="invoice-header-name">
                {CLIENT === 'woodworth' ? (
                    <>
                        <h1>Woodworth Cabinetry</h1>
                        <br />
                        <p>Address: 854 Westport Cres</p>
                        <p>Mississauga ON, L5T 1N5</p>
                        <p>Tel: 905 670 8787</p>
                        <p>https://woodworthcabinetry.com</p>
                        <p>Email: sales@de-valor.ca</p>
                    </>
                ) : (
                    <>
                        <h1>DW Cabinetry</h1>
                        <br />
                        <p>Address: 10676 King William Dr</p>
                        <p>Dallas, TX 75220 USA</p>
                        <p>Tel: 214 242 8975</p>
                        <p>https://dw-cabinetry.com</p>
                        <p>Email: sales@dw-cabinetry.com</p>
                    </>
                )}
            </div>

            <div className="invoice-header-invoice-no">
                <div>
                    <h2 style={{ textAlign: 'center' }}>Credit Memo</h2>
                </div>
                <br />
                <div>
                    <table
                        className="invoice-header-top-table"
                        style={{ width: '100%' }}
                    >
                        <tbody>
                            <tr className="invoice-header-top-table">
                                <td className="invoice-header-top-table">
                                    Date
                                </td>
                                <td className="invoice-header-top-table">
                                    Credit No.
                                </td>
                            </tr>
                            <tr className="invoice-header-top-table">
                                <td className="invoice-header-top-table">
                                    {returnOrder?.finishedDate}
                                </td>
                                <td className="invoice-header-top-table">
                                    {returnOrder?.returnInvoiceNumber}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div className="invoice-to-container">
            <div className="invoice-to-item">
                <div>Customer</div>
                <div className="invoice-to-details">
                    <p>{returnOrder?.company}</p>
                    <p>{returnOrder?.clientName}</p>
                    <p>
                        {returnOrder?.address}, {returnOrder?.addressLine2}
                    </p>
                    <p>
                        {returnOrder?.city}, {returnOrder?.province}{' '}
                        {returnOrder?.postCode}
                    </p>
                    <p>T: {returnOrder?.phone}</p>
                </div>
            </div>
        </div>
    </>
)

export default ReturnInvoiceHeader
