import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import SubmitButton from '../form/SubmitButton'
import AppInput from '../form/AppInput'
import AppForm from '../form/AppForm'
import { updateReturnOrderItemPrice } from '../../store/actions/returnOrderAction'

const validationSchema = Yup.object().shape({
    price: Yup.number().required().label('New Price'),
})

const ReturnOrderItemPriceForm = ({ setPriceOpen, returnOrderId, id }) => {
    const dispatch = useDispatch()
    return (
        <div>
            <AppForm
                initialValues={{
                    price: '',
                }}
                onSubmit={(values) => {
                    const submitValues = { ...values, id, returnOrderId }
                    dispatch(updateReturnOrderItemPrice(submitValues))
                    setPriceOpen(false)
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="price"
                    label="New Price"
                    type="number"
                    inputProps={{
                        maxLength: 10,
                    }}
                    required={true}
                />
                <SubmitButton title="Submit" />
            </AppForm>
        </div>
    )
}

export default ReturnOrderItemPriceForm
