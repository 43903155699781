import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { checkUserRole } from '../../common/checkUserRole'
import { roleListId, roleType } from '../../config/roleList'
import { ReturnOrderStatusList } from '../../constants/orderType'
import BasicModal from '../modal/BasicModal'
import ReturnOrderTaxesForm from './ReturnOrderTaxesForm'
import {
    confirmReturnOrder,
    deleteReturnOrder,
    returnOrderFinished,
    returnOrderToNew,
} from '../../store/actions/returnOrderAction'
import AppModal from '../AppModal'

const ButtonGroup = ({ returnOrder }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [taxesOpen, setTaxesOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const handleModelOpen = () => setModalOpen(true)
    const handleModalClose = () => setModalOpen(false)

    const handleDelete = useCallback(() => {
        dispatch(deleteReturnOrder(returnOrder.id, navigate))
    }, [dispatch, navigate, returnOrder.id])
    return (
        <div className="returnorder-button-group-container">
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.RETURN_ORDER,
                roleType.EDIT
            ) &&
                returnOrder.returnOrderType === ReturnOrderStatusList.NEW && (
                    <Button
                        variant="outlined"
                        size="small"
                        style={{ marginTop: '10px' }}
                        onClick={() =>
                            navigate(`/editreturnorder/${returnOrder.id}`)
                        }
                    >
                        Edit Return Order Details
                    </Button>
                )}
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.RETURN_ORDER,
                roleType.DELETE
            ) &&
                returnOrder.returnOrderType === ReturnOrderStatusList.NEW && (
                    <>
                        <AppModal
                            open={modalOpen}
                            cancelContent="Cancel"
                            submitContent="Delete"
                            title="Are you sure you want to delete this Return Order"
                            cancelClick={handleModalClose}
                            submitClick={handleDelete}
                            danger
                            handleClose={handleModalClose}
                        />
                        <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            style={{ marginTop: '10px' }}
                            onClick={handleModelOpen}
                        >
                            Delete
                        </Button>
                    </>
                )}
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.RETURN_ORDER,
                roleType.CREATE
            ) &&
                returnOrder.returnOrderType === ReturnOrderStatusList.NEW && (
                    <>
                        <BasicModal open={taxesOpen} setOpen={setTaxesOpen}>
                            <ReturnOrderTaxesForm />
                        </BasicModal>
                        <Button
                            variant="outlined"
                            size="small"
                            color="info"
                            style={{ marginTop: '10px' }}
                            onClick={() => setTaxesOpen(true)}
                        >
                            Edit Tax
                        </Button>
                    </>
                )}
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.RETURN_ORDER,
                roleType.APPROVAL
            ) &&
                returnOrder.returnOrderType === ReturnOrderStatusList.NEW && (
                    <Button
                        variant="outlined"
                        color="success"
                        size="small"
                        style={{ marginTop: '10px' }}
                        onClick={() => {
                            if (window.confirm('Confirm Return Order?')) {
                                dispatch(confirmReturnOrder(returnOrder.id))
                            }
                        }}
                    >
                        Confirm
                    </Button>
                )}
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.RETURN_ORDER,
                roleType.APPROVAL
            ) &&
                returnOrder.returnOrderType ===
                    ReturnOrderStatusList.CONFIRM && (
                    <>
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            style={{ marginTop: '10px' }}
                            onClick={() => {
                                if (window.confirm('Return Order to New?')) {
                                    dispatch(returnOrderToNew(returnOrder.id))
                                }
                            }}
                        >
                            Return to New
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            style={{ marginTop: '10px' }}
                            onClick={() => {
                                if (
                                    window.confirm('Confirm Return Order Done?')
                                ) {
                                    dispatch(
                                        returnOrderFinished(returnOrder.id)
                                    )
                                }
                            }}
                        >
                            Done
                        </Button>
                    </>
                )}
        </div>
    )
}

export default ButtonGroup
