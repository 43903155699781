import {
    CHANGE_RETURN_ORDERS_PAGE,
    GET_ALL_RETURN_ORDERS,
    GET_ONE_RETURN_ORDER,
    SEARCH_RETURN_ORDERS,
} from '../types'

const initialState = {
    returnOrders: null,
    returnOrder: null,
    pages: '',
    page: '',
    count: '',
    keyword: '',
    status: 'null',
    searchFromDate: '',
    searchToDate: '',
}

export default function returnOrderReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_RETURN_ORDERS:
            return {
                ...state,
                returnOrders: payload.returnOrders,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                status: payload.status,
                searchFromDate: payload.searchFromDate,
                searchToDate: payload.searchToDate,
                returnOrder: null,
            }
        case SEARCH_RETURN_ORDERS:
            return {
                ...state,
                keyword: payload.keyword,
                status: payload.status,
                searchFromDate: payload.searchFromDate,
                searchToDate: payload.searchToDate,
                page: 1,
            }
        case CHANGE_RETURN_ORDERS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_RETURN_ORDER:
            return {
                ...state,
                returnOrder: payload,
            }
        default:
            return state
    }
}
