import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import { formTypeList } from '../../constants/form'
import ReturnOrderForm from '../../components/returnOrder/ReturnOrderForm'
import { getOneReturnOrder } from '../../store/actions/returnOrderAction'

function EditReturnOrder() {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { returnOrder } = useSelector((state) => state.returnOrder)
    useEffect(() => {
        dispatch(getOneReturnOrder(id))
    }, [dispatch, id])
    return (
        <Layout>
            {returnOrder ? (
                <ReturnOrderForm
                    formType={formTypeList.EDIT}
                    propsState={{
                        ...returnOrder,
                    }}
                />
            ) : null}
        </Layout>
    )
}

export default EditReturnOrder
