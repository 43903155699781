/* eslint-disable no-unused-vars */
import { Button, Grid, Stack, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import request from '../../config/request'
import { AntSwitch } from '../order/OrderItemLists'
import { addReturnOrderItem } from '../../store/actions/returnOrderAction'

const AddReturnOrderItem = ({ returnOrderId, discountPercentage }) => {
    const dispatch = useDispatch()
    const [searchItems, setSearchItems] = useState([])
    const [itemId, setItemId] = useState(null)
    const [selectValue, setSelectValue] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [reusableCheck, setReusableCheck] = useState(false)
    const [controller, setController] = useState(new AbortController())

    useEffect(() => () => controller.abort(), [controller])

    const handleSearchItemChange = useCallback((option) => {
        if (option) {
            setSelectValue(option)
            setItemId(option?.value)
        } else {
            setSelectValue(null)
            setItemId(null)
        }
    }, [])

    const handleInputChange = useCallback(
        (searchKeyword) => {
            const searchValues = { searchKeyword }
            controller.abort()
            const newController = new AbortController()
            setController(newController)
            request
                .post('returnorder/searchreturnorderitem', searchValues, {
                    signal: newController.signal,
                })
                .then((res) => {
                    if (res.data.data) {
                        const selectValues = res?.data?.data?.map((item) => ({
                            value: item.id,
                            label: item.itemSKU,
                        }))
                        setSearchItems(selectValues)
                    }
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        console.log('Search request aborted')
                    } else {
                        console.error('Error fetching search items:', error)
                    }
                })
        },
        [controller]
    )

    const handleAddItem = useCallback(() => {
        if (!itemId) return false
        if (quantity <= 0) return false
        const values = {
            quantity,
            reuseable: reusableCheck,
            returnOrderId,
            itemId,
            discountPercentage,
        }

        dispatch(addReturnOrderItem(values))
        setQuantity(1)
        setSearchItems([])
        setItemId(null)
        setSelectValue(null)
        setReusableCheck(false)
        return true
    }, [
        dispatch,
        quantity,
        itemId,
        returnOrderId,
        reusableCheck,
        discountPercentage,
    ])

    return (
        <div style={{ marginTop: '50px', marginBottom: '20px' }}>
            <p>Add Item</p>
            <Grid
                container
                spacing={2}
                style={{ display: 'flex', alignItems: 'flex-end' }}
            >
                <Grid item xs={4}>
                    <Select
                        name="searchItem"
                        value={selectValue}
                        onChange={handleSearchItemChange}
                        onInputChange={handleInputChange}
                        options={searchItems}
                        placeholder="Search Item"
                        isClearable
                        isSearchable
                        style={{ zIndex: 9 }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        size="small"
                        name="quantity"
                        type="number"
                        id={Math.random().toString()}
                        label="Quantity"
                        variant="outlined"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </Grid>
                <Grid item xs={2}>
                    <div className="reuseable-container">
                        <div style={{ fontSize: '12px', color: '#666666' }}>
                            Reusable
                        </div>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <AntSwitch
                                checked={reusableCheck}
                                onChange={(e) =>
                                    setReusableCheck(e.target.checked)
                                }
                                inputProps={{ 'aria-label': 'reusable' }}
                            />
                        </Stack>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleAddItem}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default AddReturnOrderItem
