import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Button,
    Container,
    Paper,
    Table,
    TableBody,
    TableContainer,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import { getOneReturnOrder } from '../../store/actions/returnOrderAction'
import { CustomerItem } from '../Customer'
import AddReturnOrderItem from '../../components/returnOrder/AddReturnOrderItem'
import ButtonGroup from '../../components/returnOrder/ButtonGroup'
import ReturnOrderItemInfo from '../../components/returnOrder/ReturnOrderItemInfo'
import ReturnOrderTotalAmount from '../../components/returnOrder/ReturnOrderTotalAmount'
import { ReturnOrderStatusList } from '../../constants/orderType'

const ReturnOrder = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const { returnOrder } = useSelector((state) => state.returnOrder)
    useEffect(() => {
        dispatch(getOneReturnOrder(id))
    }, [dispatch, id])
    return (
        <Layout>
            <Container>
                <Button
                    style={{ marginBottom: '20px' }}
                    onClick={() => navigate('/returnorders')}
                >
                    Back
                </Button>
                {returnOrder && (
                    <>
                        {returnOrder.returnOrderType ===
                            ReturnOrderStatusList.DONE && (
                            <div
                                style={{
                                    marginBottom: '10px',
                                    marginTop: '10px',
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() =>
                                        navigate(
                                            `/returnorderinvoiceprint/${id}`
                                        )
                                    }
                                >
                                    Print Return Order Invoice
                                </Button>
                            </div>
                        )}
                        <div>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <CustomerItem
                                            title="PO"
                                            value={returnOrder.poNumber}
                                        />
                                        <CustomerItem
                                            title="Company"
                                            value={returnOrder.company}
                                        />
                                        <CustomerItem
                                            title="Client Name"
                                            value={returnOrder.clientName}
                                        />
                                        <CustomerItem
                                            title="Client Email"
                                            value={returnOrder.clientEmail}
                                        />
                                        <CustomerItem
                                            title="Address"
                                            value={returnOrder.address}
                                        />
                                        <CustomerItem
                                            title="City"
                                            value={returnOrder.city}
                                        />
                                        <CustomerItem
                                            title="Province"
                                            value={returnOrder.province}
                                        />
                                        <CustomerItem
                                            title="Postal Code"
                                            value={returnOrder.postCode}
                                        />
                                        <CustomerItem
                                            title="Country"
                                            value={returnOrder.country}
                                        />
                                        <CustomerItem
                                            title="Phone"
                                            value={returnOrder.phone}
                                        />
                                        <CustomerItem
                                            title="Discount Percentage"
                                            value={`${returnOrder.discountPercentage}%`}
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <ButtonGroup returnOrder={returnOrder} />
                            {returnOrder?.returnOrderType ===
                                ReturnOrderStatusList.NEW && (
                                <div>
                                    <AddReturnOrderItem
                                        returnOrderId={returnOrder.id}
                                        discountPercentage={
                                            returnOrder.discountPercentage
                                        }
                                    />
                                </div>
                            )}
                            <ReturnOrderItemInfo returnOrder={returnOrder} />
                            <ReturnOrderTotalAmount returnOrder={returnOrder} />
                        </div>
                    </>
                )}
            </Container>
        </Layout>
    )
}

export default ReturnOrder
