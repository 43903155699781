import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_RETURN_ORDERS, GET_ONE_RETURN_ORDER } from '../types'
import { logout, renewUserToken } from './authAction'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllReturnOrders =
    (
        keyword = '',
        status = 'null',
        pageNumber = '',
        searchFromDate = '',
        searchToDate = ''
    ) =>
    async (dispatch, getState) => {
        if (pageNumber) {
            try {
                dispatch(controlLoading(true))
                const {
                    auth: { userToken },
                } = getState()
                let res = await request.get(
                    `returnorder/listall?keyword=${keyword}&status=${status}&pageNumber=${pageNumber}&searchFromDate=${searchFromDate}&searchToDate=${searchToDate}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                )
                dispatch(renewUserToken(res))
                dispatch({
                    type: GET_ALL_RETURN_ORDERS,
                    payload: {
                        returnOrders: res.data.data,
                        pages: res.data.pages,
                        page: res.data.page,
                        count: res.data.count,
                        keyword: keyword,
                        status,
                        searchFromDate,
                        searchToDate,
                    },
                })
                dispatch(controlLoading(false))
            } catch (e) {
                errorsReturn(e, dispatch, controlLoading, getErrors, logout)
            }
        }
    }

export const getOneReturnOrder = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.get(`returnorder/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_RETURN_ORDER, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addNewReturnOrder =
    (values, navigate) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.post(
                `returnorder/createreturnorder`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            navigate(`/returnorder/${res.data.data}`)
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateReturnOrder =
    (values, navigate) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.put(
                `returnorder/updatereturnorder/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            navigate(`/returnorder/${values.id}`)
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const deleteReturnOrder =
    (id, navigate) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.delete(
                `returnorder/deletereturnorder/${id}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            navigate(`/returnorders`)
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const addReturnOrderItem = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.post(`returnorder/addreturnorderitem`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getOneReturnOrder(values.returnOrderId))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteReturnOrderItem =
    (id, returnOrderId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.delete(
                `returnorder/deletereturnorderitem/${id}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getOneReturnOrder(returnOrderId))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateReturnOrderItemPrice =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const res = await request.put(
                `returnorder/updatereturnorderitemprice/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneReturnOrder(values.returnOrderId))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const addReturnOrderTax = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.post(
            `returnorder/addreturnordertax`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        dispatch(getOneReturnOrder(values.returnOrderId))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteReturnOrderTax =
    (id, returnOrderId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const res = await request.delete(
                `returnorder/deletereturnordertax/${id}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                    params: { returnOrderId },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneReturnOrder(returnOrderId))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const confirmReturnOrder =
    (id, values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const res = await request.put(
                `returnorder/confirmreturnorder/${id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneReturnOrder(id))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const returnOrderToNew = (id, values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        const res = await request.put(
            `returnorder/backreturnordertonew/${id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        dispatch(getOneReturnOrder(id))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const returnOrderFinished =
    (id, values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            const res = await request.put(
                `returnorder/returnorderdone/${id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(getOneReturnOrder(id))
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
