import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import Layout from '../../components/Layout'
import PagnationComponent from '../../components/PagnationComponent'
import { Button, Container, Grid, Typography } from '@mui/material'
import { CHANGE_RETURN_ORDERS_PAGE } from '../../store/types'
import SearchReturnOrder from '../../components/returnOrder/SearchReturnOrder'
import ReturnOrderInfo from '../../components/returnOrder/ReturnOrderInfo'
import { getAllReturnOrders } from '../../store/actions/returnOrderAction'
import { returnOrderTypeList } from '../../constants/orderType'
import request from '../../config/request'
import { controlLoading } from '../../store/actions/loadingActions'
import { getErrors } from '../../store/actions/errorActions'

const ReturnOrders = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        returnOrders,
        pages,
        page,
        count,
        keyword,
        status,
        searchFromDate,
        searchToDate,
    } = useSelector((state) => state.returnOrder)
    const { userToken } = useSelector((state) => state.auth)

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(
            getAllReturnOrders(
                keyword,
                status,
                pageNumber,
                searchFromDate,
                searchToDate
            )
        )
    }, [dispatch, keyword, status, pageNumber, searchFromDate, searchToDate])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_RETURN_ORDERS_PAGE, payload: page })
        },
        [dispatch]
    )

    const handleDownload = useCallback(async () => {
        dispatch(controlLoading(true))

        try {
            const res = await request.get(
                `returnorder/listall?keyword=${keyword}&status=${status}&searchFromDate=${searchFromDate}&searchToDate=${searchToDate}&download=true`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )

            const returnOrders = res?.data?.data || []

            if (returnOrders.length === 0) {
                dispatch(controlLoading(false))
                return
            }

            const downloadData = returnOrders.map((returnOrder) => {
                return {
                    returnOrderType:
                        returnOrderTypeList[returnOrder?.returnOrderType] ||
                        'Unknown',
                    invoiceNumber: returnOrder?.returnInvoiceNumber || 'N/A',
                    invoiceDate: returnOrder?.finishedDate || 'N/A',
                    poNumber: returnOrder?.poNumber || 'N/A',
                    company: returnOrder?.company || 'N/A',
                    client: returnOrder?.clientName || 'N/A',
                    returnOrderAmount: parseFloat(
                        returnOrder?.returnOrderAmount || 0
                    ),
                }
            })

            const worksheet = XLSX.utils.json_to_sheet(downloadData)
            const workbook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workbook, worksheet, 'ReturnOrders')

            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            })

            const blob = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })

            saveAs(blob, `ReturnOrders_${Date.now()}.xlsx`)
        } catch (error) {
            dispatch(getErrors('Download error'))
            console.error('Download error:', error)
        } finally {
            dispatch(controlLoading(false))
        }
    }, [userToken, keyword, status, searchFromDate, searchToDate, dispatch])

    return (
        <Layout>
            <Container>
                <Button
                    color="info"
                    variant="outlined"
                    onClick={() => navigate(`/customers`)}
                >
                    Add New Return Order
                </Button>
                <SearchReturnOrder
                    displayKeyword={keyword}
                    searchStatus={status}
                    displayFromDate={searchFromDate}
                    displayToDate={searchToDate}
                />
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                >
                    <Typography
                        variant="body2"
                        gutterBottom
                        style={{ paddingBottom: '10px', paddingTop: '10px' }}
                    >
                        Count: {count}
                    </Typography>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            type="button"
                            onClick={handleDownload}
                        >
                            Download
                        </Button>
                    </Grid>
                </Grid>
                {returnOrders && (
                    <ReturnOrderInfo returnOrders={returnOrders} />
                )}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default ReturnOrders
