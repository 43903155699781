import {
    Button,
    Container,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
    getAllUserList,
    getOneCustomer,
    updateCustomer,
} from '../store/actions/customerAction'
import Layout from '../components/Layout'
import { GET_ONE_CUSTOMER } from '../store/types'
import AppCheckbox from '../components/form/AppCheckbox'
import AppForm from '../components/form/AppForm'
import AppSelect from '../components/form/AppSelect'
import selectList from '../constants/selectList.json'
import SubmitButton from '../components/form/SubmitButton'
import { roleListId, roleType } from '../config/roleList'
import { checkUserRole } from '../common/checkUserRole'
import AppReactSelect from '../components/form/AppReactSelect'
import AppInput from '../components/form/AppInput'

export const CustomerItem = ({ title, value }) => (
    <TableRow>
        <TableCell style={{ width: '200px' }}>{title}</TableCell>
        <TableCell>{value}</TableCell>
    </TableRow>
)

const CustomerPicture = ({ title, url }) => {
    const pathIndex = url.lastIndexOf('.')
    const fileType = url.substr(pathIndex + 1).toLowerCase()
    return (
        <TableRow>
            <TableCell style={{ width: '200px' }}>{title}</TableCell>
            <TableCell>
                <a href={`${url}`} target="_black">
                    {fileType === 'pdf' ? (
                        <img
                            src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1615948739/samples/pdf_nw6xuu.png"
                            alt="pdf"
                            width="100px"
                        />
                    ) : (
                        <img
                            src={url}
                            alt="customer pic"
                            className="display-contact-pic"
                            onError={(event) => {
                                event.target.src =
                                    'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                event.onerror = null
                            }}
                        />
                    )}
                </a>
            </TableCell>
        </TableRow>
    )
}

const Customer = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const { customer, userList } = useSelector((state) => state.customer)

    useEffect(() => {
        dispatch(getOneCustomer(id))
        return () => {
            dispatch({ type: GET_ONE_CUSTOMER, payload: null })
        }
    }, [id, dispatch])

    useEffect(() => {
        if (
            checkUserRole(
                userInfo,
                roleList,
                roleListId.CUSTOMER,
                roleType.EDIT
            )
        ) {
            dispatch(getAllUserList())
        }
    }, [dispatch, userInfo, roleList])

    return customer ? (
        <Layout>
            <Container>
                <Button
                    style={{ marginBottom: '20px' }}
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
                {checkUserRole(
                    userInfo,
                    roleList,
                    roleListId.CUSTOMER,
                    roleType.EDIT
                ) && (
                    <AppForm
                        initialValues={{
                            isActive: customer.isActive,
                            webRole: customer.webRole,
                            defaultDiscountPercentage:
                                customer.defaultDiscountPercentage,
                            userIds: customer?.users?.map((user) => user.id),
                        }}
                        onSubmit={(values) => {
                            values['id'] = customer.id
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.CUSTOMER,
                                roleType.EDIT
                            )
                                ? dispatch(updateCustomer(values, navigate))
                                : alert('Not Permit User')
                        }}
                    >
                        <AppCheckbox name="isActive" label="Is Active" />
                        <AppSelect
                            name="webRole"
                            label="Role Level"
                            options={selectList.webRoleList.list}
                        />
                        <AppInput
                            name="defaultDiscountPercentage"
                            label="Dealer Discount Percentage"
                            type="number"
                            inputProps={{ maxLength: 10 }}
                            required={true}
                        />
                        <AppReactSelect
                            options={userList}
                            name="userIds"
                            label="User Access this Dealer's orders"
                        />
                        <SubmitButton title="Submit" />
                    </AppForm>
                )}
                <div style={{ height: '10px' }} />
                <Typography variant="h6" style={{ marginBottom: '10px' }}>
                    Company Info
                </Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <CustomerItem
                                title="Account No"
                                value={customer.accountNo}
                            />
                            <CustomerItem
                                title="Company Name"
                                value={customer.company}
                            />
                            <CustomerItem
                                title="Business Number"
                                value={customer.businessNumber}
                            />
                            <CustomerItem
                                title="Owner Name"
                                value={`${customer.firstName} ${customer.lastName}`}
                            />
                            <CustomerItem
                                title="Address"
                                value={customer.address}
                            />
                            <CustomerItem title="City" value={customer.city} />
                            <CustomerItem
                                title="Province"
                                value={customer.province}
                            />
                            <CustomerItem
                                title="Postal Code"
                                value={customer.postCode}
                            />
                            <CustomerItem
                                title="Country"
                                value={customer.country}
                            />
                            <CustomerItem
                                title="Phone"
                                value={customer.phone}
                            />
                            <CustomerItem title="Fax" value={customer.fax} />
                            <CustomerItem
                                title="Email"
                                value={customer.email}
                            />
                            <CustomerItem
                                title="Website"
                                value={customer.website}
                            />
                            <CustomerItem
                                title="Business Startup Year"
                                value={customer.startupYear}
                            />
                            <CustomerItem
                                title="Type of Business"
                                value={customer.businessType}
                            />
                            <CustomerItem
                                title="Occupation"
                                value={customer.occupation}
                            />
                            <CustomerPicture
                                title="Business License"
                                url={customer.businessLicense}
                            />
                        </TableBody>
                    </Table>
                </TableContainer>
                {customer?.customercontacts?.length > 0 && (
                    <>
                        <Typography
                            variant="h6"
                            style={{ marginBottom: '10px', marginTop: '10px' }}
                        >
                            Contact Information
                        </Typography>
                        {customer?.customercontacts?.map((contact) => (
                            <TableContainer key={contact.id} component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <CustomerItem
                                            title="Contact Name"
                                            value={`${contact.contactFirstName} ${contact.contactLastName}`}
                                        />
                                        <CustomerItem
                                            title="Position"
                                            value={contact.position}
                                        />
                                        <CustomerItem
                                            title="Phone"
                                            value={contact.contactPhone}
                                        />
                                        <CustomerItem
                                            title="Email"
                                            value={contact.contactEmail}
                                        />
                                        <CustomerPicture
                                            title="Business Card"
                                            url={contact.businessCard}
                                        />
                                        <CustomerPicture
                                            title="Driver License"
                                            url={contact.driverLicense}
                                        />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ))}
                    </>
                )}
            </Container>
        </Layout>
    ) : null
}

export default Customer
