import {
    Card,
    CardContent,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import BlockIcon from '@mui/icons-material/Block'
import { green, red } from '@mui/material/colors'
import { MdDeleteForever } from 'react-icons/md'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import React, { useCallback, useState } from 'react'
import BasicModal from '../modal/BasicModal'
import ReturnOrderItemPriceForm from './ReturnOrderItemPriceForm'
import { ReturnOrderStatusList } from '../../constants/orderType'
import AppModal from '../AppModal'
import { deleteReturnOrderItem } from '../../store/actions/returnOrderAction'

const ReturnOrderItemLists = ({
    returnOrderItem,
    returnOrderId,
    returnOrderType,
    printType,
}) => {
    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const handleModelOpen = () => setModalOpen(true)
    const handleModalClose = () => setModalOpen(false)
    const [priceOpen, setPriceOpen] = useState(false)

    const handleDelete = useCallback(() => {
        dispatch(deleteReturnOrderItem(returnOrderItem.id, returnOrderId))
    }, [dispatch, returnOrderItem.id, returnOrderId])
    return (
        <>
            <TableRow>
                <TableCell>{returnOrderItem?.quantity}</TableCell>
                <TableCell>{returnOrderItem?.item?.itemSKU}</TableCell>
                <TableCell
                    style={
                        returnOrderType === ReturnOrderStatusList.NEW
                            ? { cursor: 'pointer' }
                            : null
                    }
                    onClick={() =>
                        returnOrderType === ReturnOrderStatusList.NEW
                            ? setPriceOpen(true)
                            : null
                    }
                >
                    ${returnOrderItem?.price?.toFixed(2)}
                </TableCell>
                {!printType && (
                    <>
                        <TableCell>
                            {returnOrderItem?.price !==
                                returnOrderItem?.basePrice && (
                                <span
                                    style={{
                                        color: 'red',
                                        textDecorationLine: 'line-through',
                                    }}
                                >
                                    ${returnOrderItem?.basePrice?.toFixed(2)}
                                </span>
                            )}
                        </TableCell>

                        <TableCell>
                            {returnOrderItem?.reuseable ? (
                                <CheckCircleOutlineIcon
                                    style={{ color: green[500] }}
                                />
                            ) : (
                                <BlockIcon style={{ color: red[500] }} />
                            )}
                        </TableCell>
                    </>
                )}
                <TableCell>
                    $
                    {(returnOrderItem.quantity * returnOrderItem.price).toFixed(
                        2
                    )}
                </TableCell>
                {!printType && (
                    <TableCell>
                        {returnOrderType === ReturnOrderStatusList.NEW && (
                            <>
                                <div>
                                    <AppModal
                                        open={modalOpen}
                                        cancelContent="Cancel"
                                        submitContent="Delete"
                                        title="Are you sure you want to delete"
                                        cancelClick={handleModalClose}
                                        submitClick={handleDelete}
                                        danger
                                        handleClose={handleModalClose}
                                    />
                                    <MdDeleteForever
                                        color="#FF0000"
                                        size={20}
                                        style={{ cursor: 'pointer' }}
                                        onClick={handleModelOpen}
                                    />
                                </div>
                                <div>
                                    <BasicModal
                                        open={priceOpen}
                                        setOpen={setPriceOpen}
                                    >
                                        <ReturnOrderItemPriceForm
                                            setPriceOpen={setPriceOpen}
                                            returnOrderId={returnOrderId}
                                            id={returnOrderItem?.id}
                                        />
                                    </BasicModal>
                                </div>
                            </>
                        )}
                    </TableCell>
                )}
            </TableRow>
        </>
    )
}

const ReturnOrderItemInfo = ({ returnOrder, printType = false }) => {
    return (
        <Card className="order-item-card">
            <CardContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Qty</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Price</TableCell>
                                {!printType && (
                                    <>
                                        <TableCell>Base Price</TableCell>
                                        <TableCell>Reusable</TableCell>
                                    </>
                                )}
                                <TableCell>Amount</TableCell>
                                {!printType && <TableCell></TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {returnOrder?.returnorderitems?.map(
                                (returnOrderItem) => (
                                    <ReturnOrderItemLists
                                        returnOrderItem={returnOrderItem}
                                        key={returnOrderItem.id}
                                        returnOrderId={returnOrder.id}
                                        returnOrderType={
                                            returnOrder.returnOrderType
                                        }
                                        printType={printType}
                                    />
                                )
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
    )
}

export default ReturnOrderItemInfo
