import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ReturnOrderList from './ReturnOrderList'

const ReturnOrderInfo = ({ returnOrders }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Type</TableCell>
                        <TableCell>Invoice No</TableCell>
                        <TableCell>PO</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Return Amount</TableCell>
                        <TableCell style={{ width: '50px' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {returnOrders?.map((returnOrder) => (
                        <ReturnOrderList
                            returnOrder={returnOrder}
                            key={returnOrder.id}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ReturnOrderInfo
