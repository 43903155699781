import React from 'react'
import ReturnInvoiceHeader from './ReturnInvoiceHeader'
import ReturnOrderItemInfo from './ReturnOrderItemInfo'
import ReturnOrderTotalAmount from './ReturnOrderTotalAmount'

const ReturnOrderInvoiceToPrint = React.forwardRef(({ returnOrder }, ref) => (
    <div className="invoice-container" ref={ref}>
        <div className="invoice-content">
            <ReturnInvoiceHeader returnOrder={returnOrder} />
            <div style={{ marginTop: '30px' }} />
            <div className="order-product-items">
                <ReturnOrderItemInfo
                    returnOrder={returnOrder}
                    printType={true}
                />
            </div>
            <ReturnOrderTotalAmount returnOrder={returnOrder} />
        </div>
    </div>
))

export default ReturnOrderInvoiceToPrint
