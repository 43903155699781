import React from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { Button, Container, Divider, Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import selectList from '../../constants/selectList.json'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import AppSelect from '../form/AppSelect'
import SubmitButton from '../form/SubmitButton'
import { formTypeList } from '../../constants/form'
import {
    addNewReturnOrder,
    updateReturnOrder,
} from '../../store/actions/returnOrderAction'

const validationSchema = Yup.object().shape({
    poNumber: Yup.string().required().min(2).max(30).label('PO Number'),
    company: Yup.string().required().min(2).max(100).label('Company'),
    clientName: Yup.string().required().min(2).max(100).label('Client Name'),
    clientEmail: Yup.string().required().email().max(100).label('Client Email'),
    phone: Yup.string().required().min(2).max(20).label('Phone'),
    address: Yup.string().required().min(2).max(200).label('Address'),
    addressLine2: Yup.string().min(2).max(100).label('Apt or Suit #'),
    city: Yup.string().required().max(40).label('City'),
    province: Yup.string().required().max(80).label('Province'),
    postCode: Yup.string().required().max(40).label('Postal Code'),
    country: Yup.string().required().max(40).label('Country'),
})

function ReturnOrderForm({ formType, propsState = null }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    return (
        <Container>
            <Button
                style={{ marginLeft: '30px', marginTop: '20px' }}
                variant="outlined"
                color="grey"
                onClick={() => navigate(-1)}
            >
                Back
            </Button>
            <h2 className="signin-header">Return Order</h2>
            <Paper elevation={3} max-width="md" className="order-paper">
                <div className="bg-white p-8 mt-20 mb-20 rounded shadow-lg w-[300px] sm:w-[500px] md:w-[700px]">
                    <AppForm
                        initialValues={{
                            poNumber: propsState ? propsState.poNumber : '',
                            company: propsState ? propsState.company : '',
                            clientName: propsState ? propsState.clientName : '',
                            clientEmail: propsState
                                ? propsState.clientEmail
                                : '',
                            phone: propsState ? propsState.phone : '',
                            address: propsState ? propsState.address : '',
                            addressLine2: propsState
                                ? propsState.addressLine2
                                : '',
                            city: propsState ? propsState.city : '',
                            province: propsState ? propsState.province : '',
                            postCode: propsState ? propsState.postCode : '',
                            country: propsState ? propsState.country : '',
                        }}
                        onSubmit={async (values) => {
                            if (formType === formTypeList.NEW) {
                                const updatedValues = {
                                    ...values,
                                    customerId: propsState.customerId,
                                }
                                dispatch(
                                    addNewReturnOrder(updatedValues, navigate)
                                )
                            } else {
                                const updatedValues = {
                                    ...propsState,
                                    ...values,
                                }
                                dispatch(
                                    updateReturnOrder(updatedValues, navigate)
                                )
                            }
                        }}
                        validationSchema={validationSchema}
                    >
                        <AppInput
                            name="poNumber"
                            label="PO Number"
                            type="text"
                            required
                            inputprops={{ maxLength: 30 }}
                        />
                        <AppInput
                            name="company"
                            label="Company"
                            type="text"
                            required
                            inputprops={{ maxLength: 100 }}
                        />
                        <AppInput
                            name="clientName"
                            label="Client Name"
                            type="text"
                            required
                            inputprops={{ maxLength: 100 }}
                        />
                        <AppInput
                            name="clientEmail"
                            label="Client Email"
                            type="email"
                            required
                            inputprops={{ maxLength: 100 }}
                        />
                        <AppInput
                            name="phone"
                            label="Phone"
                            type="text"
                            required
                            inputprops={{ maxLength: 20 }}
                        />
                        <Divider>Additional Billing Info</Divider>

                        <div
                            style={{ marginTop: '10px', marginBottom: '10px' }}
                        >
                            Billing Address
                        </div>
                        <AppInput
                            name="address"
                            label="Address"
                            type="text"
                            required
                            inputprops={{ maxLength: 200 }}
                        />
                        <AppInput
                            name="addressLine2"
                            label="Apt or Suit #"
                            type="text"
                            inputprops={{ maxLength: 200 }}
                        />
                        <AppInput
                            name="city"
                            label="City"
                            type="text"
                            required
                            inputprops={{ maxLength: 40 }}
                        />
                        <AppInput
                            name="province"
                            label="Province"
                            type="text"
                            required
                            inputprops={{ maxLength: 80 }}
                        />
                        <AppInput
                            name="postCode"
                            label="Postal Code"
                            type="text"
                            required
                            inputprops={{ maxLength: 40 }}
                        />
                        <AppSelect
                            name="country"
                            label="Country"
                            required
                            options={selectList.country.list}
                        />
                        <SubmitButton title="Continue" />
                    </AppForm>
                </div>
            </Paper>
        </Container>
    )
}

export default ReturnOrderForm
