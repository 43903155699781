import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CustomerList from './CustomerList'

const CustomerInfos = ({ customers }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Company</TableCell>
                        <TableCell>Owners</TableCell>
                        <TableCell>Phone</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Role Level</TableCell>
                        <TableCell>Discount</TableCell>
                        <TableCell>Orders</TableCell>
                        <TableCell align="center">Verfy</TableCell>
                        <TableCell align="center">Active</TableCell>
                        <TableCell style={{ width: '280px' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {customers.map((customer) => (
                        <CustomerList customer={customer} key={customer.id} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default CustomerInfos
