import React, { useCallback, useEffect, useState } from 'react'
import { TotalItem } from '../order/TotalAmount'

const ReturnOrderTotalAmount = ({ returnOrder }) => {
    const [returnOrderTotal, setReturnOrderTotal] = useState(0)
    const [taxes, setTaxes] = useState([])
    const [amountTotal, setAmountTotal] = useState(0)

    const totalCalculation = useCallback(() => {
        if (returnOrder) {
            const totalReturnOrderPrice =
                returnOrder?.returnorderitems?.reduce(
                    (acc, item) => acc + item.quantity * item.price,
                    0
                ) || 0
            setReturnOrderTotal(totalReturnOrderPrice)
            let taxTotal = 0
            const taxItem = returnOrder?.returnordertaxs?.map((tax) => ({
                label: tax.taxLabel,
                taxRate: tax.taxRate,
                taxFee: (tax.taxRate / 100) * totalReturnOrderPrice,
            }))
            setTaxes(taxItem)
            if (taxItem?.length > 0) {
                taxTotal = taxItem.reduce((acc, item) => acc + item.taxFee, 0)
            }
            const totalAmount = totalReturnOrderPrice + taxTotal
            setAmountTotal(totalAmount)
        }
    }, [returnOrder])

    useEffect(() => {
        totalCalculation()
    }, [totalCalculation])
    return (
        <div>
            <div className="order-review-total-box">
                <TotalItem
                    left="Return Amount before Tax"
                    right={returnOrderTotal}
                    negative={true}
                />
            </div>
            {taxes?.length > 0 && (
                <div>
                    {taxes.map((tax) => (
                        <div
                            className="order-review-total-box"
                            key={Math.random()}
                        >
                            <TotalItem
                                left={`${tax?.label}(${tax?.taxRate}%)`}
                                right={tax?.taxFee}
                                negative={true}
                            />
                        </div>
                    ))}
                </div>
            )}
            <div className="order-review-total-box">
                <TotalItem
                    fontBold={true}
                    left="Total Return Amount"
                    right={amountTotal}
                    negative={true}
                />
            </div>
        </div>
    )
}

export default ReturnOrderTotalAmount
