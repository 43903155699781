import { Button, TableCell, TableRow } from '@mui/material'
import { green, red } from '@mui/material/colors'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import LoginIcon from '@mui/icons-material/Login'

const CustomerList = ({ customer }) => {
    const navigate = useNavigate()
    const { userInfo, userToken } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    return (
        <>
            <TableRow>
                <TableCell>{customer.company}</TableCell>
                <TableCell>
                    {customer.firstName} {customer.lastName}
                </TableCell>
                <TableCell>{customer.phone}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.webRole}</TableCell>
                <TableCell>{customer.defaultDiscountPercentage}%</TableCell>
                <TableCell>{customer.orderCount}</TableCell>
                <TableCell align="center">
                    {customer.verify ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>
                <TableCell align="center">
                    {customer.isActive ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>
                <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.CUSTOMER,
                                    roleType.READ
                                )
                                    ? navigate(`/customer/${customer.id}`)
                                    : alert('Not Permit User')
                            }
                        >
                            Info
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            style={{ marginLeft: '5px' }}
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.RETURN_ORDER,
                                    roleType.CREATE
                                )
                                    ? navigate(`/addreturnorder/${customer.id}`)
                                    : alert('Not Permit User')
                            }
                        >
                            + Return
                        </Button>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`${process.env.REACT_APP_AGENT_URL}/adminlogin?access_token=${userToken}&customer_id=${customer.id}`}
                        >
                            <LoginIcon
                                style={{
                                    marginLeft: '5px',
                                    color: 'darkgreen',
                                    cursor: 'pointer',
                                }}
                            />
                        </a>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default CustomerList
