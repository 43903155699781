import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import { formTypeList } from '../../constants/form'
import ReturnOrderForm from '../../components/returnOrder/ReturnOrderForm'
import { getOneCustomer } from '../../store/actions/customerAction'

function AddReturnOrder() {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { customer } = useSelector((state) => state.customer)
    useEffect(() => {
        dispatch(getOneCustomer(id))
    }, [dispatch, id])
    return (
        <Layout>
            {customer ? (
                <ReturnOrderForm
                    formType={formTypeList.NEW}
                    propsState={{
                        ...customer,
                        customerId: customer.id,
                        clientName: `${customer.firstName} ${customer.lastName}`,
                        clientEmail: customer.email,
                        poNumber: '',
                    }}
                />
            ) : null}
        </Layout>
    )
}

export default AddReturnOrder
